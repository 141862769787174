:root {
  --reach-dialog: 1;
  --white: #fff;
  --muted: #7E868C;
  --primary: #0074D9;
  --primary-hover: #035FBE;
  --secondary: #F71C1C;
  --secondary-hover: #CD0303;
  --tertiary: #028074;
  --tertiary-hover: #046960;
  --dark: #3A3838;
  --tint-pink: #f7acb2;
  --tint-yellow: #FFDFB1;
  --tint-green: #e9ffe7;
  --text-danger: #F25544;
  --custom-font-family: 'Open Sans', sans-serif;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
