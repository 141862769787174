  body {
    margin: 0;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    background-color: var(--white);
    overflow-x: hidden;
  }

  a {
    color: var(--primary);
    text-decoration: underline;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button:focus {
    box-shadow: none;
    outline: none;
  }

  select {
    word-wrap: normal;
  }

  textarea {
    resize: vertical;
  }

  .button {
    --button-radius: 3px;
    --button-line-height: 1.5;
    --button-text-color: white;
    --button-color: var(--primary);
    --button-font-size: 16px;
    --button-font-weight: bold;
    --button-padding: 11px 18px;
    --button-hover-text-color: white;
    --button-hover-color: var(--primary-hover);
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    text-decoration: none;
    color: var(--button-text-color);
    line-height: var(--button-line-height);
    font-weight: var(--button-font-weight);
    font-size: var(--button-font-size);
    padding: var(--button-padding);
    border-radius: var(--button-radius);
    transition: all 300ms ease 0ms;
    border: 1px solid var(--button-color);
    background-color: var(--button-color);
    cursor: pointer;

    &:focus,
    &:hover {
      color: var(--button-hover-text-color);
      border-color: var(--button-hover-color);
      background-color: var(--button-hover-color);
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .secondary {
    --button-color: var(--secondary);
    --button-hover-color: var(--secondary-hover);
  }

  .tertiary {
    --button-color: var(--tertiary);
    --button-hover-color: var(--tertiary-hover);
  }

  .button-sm {
    --button-font-size: 14px;
    --button-font-weight: normal;
    --button-padding: 9px 17px;
  }

  .danger-alert-text {
    font-size: 14px;
    color: var(--text-danger);
  }

  .form-control {
    @extend .block;
    @extend .w-100;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #C6D0C3;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out;

    &:focus {
      outline: none;
      border-color: #80af71;
    }
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  textarea.form-control {
    height: auto;
  }

  .custom-select {
    @extend .inline-block;
    @extend .w-100;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--dark);
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
    border: 1px solid #C6D0C3;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
      border-color: #80af71;
    }
  }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .embed-responsive::before {
    display: block;
    content: "";
  }

  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }

  .embed-responsive-4by3::before {
    padding-top: 75%;
  }

  .embed-3by4::before {
    padding-top: 133%;
  }

  .embed-responsive .embed-responsive-item,
  .embed-responsive embed,
  .embed-responsive iframe,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background-color: #AFBDC0;
  }

  .fn__video {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
    background-color: #AFBDC0;

    @media ( min-width: 577px) {
      max-height: 530px;
      overflow: hidden;
    }
  }

  .fn__video__01 {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    position: absolute;
    background-color: #AFBDC0;
  }

  .mobile-flip {
    @media (max-width: 576px) {
      -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
  }

  .img-hidden {
    visibility: hidden;
  }

  .toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none;
  }

  .toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: .25rem;
  }

  .toast-header {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
  }

  .toast-body {
    padding: .75rem;
    word-wrap: break-word;
  }
